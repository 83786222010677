<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div
      class="modal fade"
      id="modal-form-tep-preliquidacion-export"
      style="background-color: #00000082"
    >
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Generar Reporte Preliquidaciones
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="tab-Filtros"
                  data-toggle="tab"
                  href="#Filtros"
                  >Filtros
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade active show" id="Filtros">
                <div class="card-body">
                  <div class="form-group row">
                    <label class="col-md-6">Id</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.id"
                    />
                  </div>
                  <div class="form-group row">
                    <label class="col-md-6">Transportadora</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="transportadora"
                      placeholder="Razon social"
                      label="razon_social"
                      class="form-control form-control-sm p-0 col-md-12"
                      :options="listasForms.transportadoras"
                      @input="seleccionarTransportadora()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-6">Tipo de Operación</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="tipo_operacion"
                      placeholder="Tipo Operación"
                      label="descripcion"
                      class="form-control form-control-sm p-0 col-md-12"
                      :options="listasForms.tipos_operaciones"
                      @input="seleccionarTipoOperacion()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-6">Sitio</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="operacion"
                      placeholder="Sitio"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-12"
                      :options="listasForms.operaciones"
                      @input="seleccionarOperacion()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label for="estado" class="col-md-6">Estado</label>
                    <select
                      id="estado"
                      v-model="filtros.estado"
                      class="form-control form-control-sm"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-6">Tipo de Vehículo</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="tipo_vehiculo"
                      placeholder="Tipo Vehículo"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-12"
                      :options="listasForms.tipos_vehiculos"
                      @input="seleccionarTipoVehiculo()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-6">Vehículo</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="vehiculo"
                      placeholder="Placa"
                      label="placa"
                      class="form-control form-control-sm p-0 col-md-12"
                      :options="listasForms.vehiculos"
                      @input="seleccionarVehiculo()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-6">Conductor</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="conductor"
                      placeholder="Conductror"
                      label="nConductor"
                      class="form-control form-control-sm p-0 col-md-12"
                      :options="listasForms.conductores"
                      @input="seleccionarConductor()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-6">Turno</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="select_turno"
                      placeholder="Turno"
                      label="nombre"
                      class="form-control form-control-sm p-0 col-md-12"
                      :options="listasForms.turnos"
                      @input="seleccionarTurno()"
                    ></v-select>
                  </div>
                  <div class="form-group row">
                    <label class="col-md-6">Desde</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_ini"
                      @input="validaFechasFiltro()"
                    />
                  </div>
                  <div class="form-group row">
                    <label class="col-md-6">Hasta</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_fin"
                      @input="validaFechasFiltro()"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-between">
            <div>
              <button
                type="button"
                class="btn btn-sm bg-navy"
                @click="generarListadoExcel()"
                v-if="$store.getters.can('tep.preliquidacionesInternos.export')"
              >
                Generar Listado
                <i class="fas fa-file-download"> </i>
              </button>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
export default {
  name: "TepPreliquidacionInternoExport",
  components: {
    Loading,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      filtros: {
        id: null,
        empresa_id: null,
        fecha_ini: null,
        fecha_fin: null,
        tipo_operacion: null,
        operacion_id: null,
        estado: null,
        tipo_vehiculo_id: null,
        vehiculo_id: null,
        conductor_id: null,
        turno_id: null,
      },
      listasForms: {
        transportadoras: [],
        tipos_vehiculos: [],
        vehiculos: [],
        conductores: [],
        tipos_operaciones: [],
        operaciones: [],
        turnos: [],
        estados: [],
      },
      transportadora: null,
      tipo_vehiculo: null,
      select_turno: null,
      vehiculo: null,
      conductor: null,
      tipo_operacion: null,
      operacion: null,
      fecha_ini: null,
      fecha_fin: null,
    };
  },

  methods: {
    getTransportadora() {
      let me = this;
      axios
        .get("api/admin/empresas/listaTep?estado=1")
        .then(function(response) {
          me.listasForms.transportadoras = response.data;
        });
    },

    async getEstados() {
      await axios.get("/api/lista/98").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    seleccionarTransportadora() {
      if (this.transportadora != null) {
        this.filtros.empresa_id = this.transportadora.id;
      } else {
        this.filtros.empresa_id = null;
      }
    },

    /*     getTiposVehiculos() {
      let me = this;
      axios.get("/api/admin/tiposVehiculos/lista").then((response) => {
        me.listasForms.tipos_vehiculos = response.data;
      });
    }, */

    getTiposVehiculos(tipo_vehiculos) {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 5,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipos_vehiculos = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    seleccionarTipoVehiculo() {
      if (this.tipo_vehiculo != null) {
        this.filtros.tipo_vehiculo_id = this.tipo_vehiculo.id;
      } else {
        this.filtros.tipo_vehiculo_id = null;
      }
    },

    getVehiculos() {
      let me = this;
      axios.get("/api/admin/vehiculos/lista").then((response) => {
        me.listasForms.vehiculos = response.data;
      });
    },

    seleccionarVehiculo() {
      if (this.vehiculo != null) {
        this.filtros.vehiculo_id = this.vehiculo.id;
      } else {
        this.filtros.vehiculo_id = null;
      }
    },

    getConductores() {
      let me = this;
      axios.get("/api/admin/conductores/lista").then((response) => {
        me.listasForms.conductores = response.data;
      });
    },

    seleccionarConductor() {
      if (this.conductor != null) {
        this.filtros.conductor_id = this.conductor.id;
      } else {
        this.filtros.conductor_id = null;
      }
    },

    getTipoOperacion() {
      let me = this;
      axios.get("/api/lista/80").then((response) => {
        me.listasForms.tipos_operaciones = response.data;
      });
    },

    seleccionarTipoOperacion() {
      if (this.tipo_operacion != null) {
        this.filtros.tipo_operacion = this.tipo_operacion.numeracion;
      } else {
        this.filtros.tipo_operacion = null;
      }
    },

    getOperaciones() {
      let me = this;
      axios.get("/api/admin/sitios/lista").then((response) => {
        me.listasForms.operaciones = response.data;
      });
    },

    seleccionarOperacion() {
      if (this.operacion != null) {
        this.filtros.operacion_id = this.operacion.id;
      } else {
        this.filtros.operacion_id = null;
      }
    },

    getTurnos() {
      axios.get("/api/admin/turnos/lista").then((response) => {
        this.listasForms.turnos = response.data;
      });
    },

    seleccionarTurno() {
      if (this.select_turno != null) {
        this.filtros.turno_id = this.select_turno.id;
      } else {
        this.filtros.turno_id = null;
      }
    },

    generarListadoExcel() {
      // this.cargando = true;
      axios({
        method: "POST",
        url: "/api/tep/preliquidacionesInternos/export",
        data: this.filtros,
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          location.href = data.url;
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    this.cargando = true;
    this.getTransportadora();
    this.getTiposVehiculos();
    this.getVehiculos();
    this.getConductores();
    this.getTipoOperacion();
    this.getOperaciones();
    this.getEstados();
    this.getTurnos();
    this.cargando = false;
  },
};
</script>
